import isElement from 'lodash/isElement'

const CONSTANTS = {
  VX_MAX: 2,
  VY_MAX: 5,
  VY_ACCELERATE: 0.01,
  FLAKE_COLOR: 'lightblue',
}

class SnowFlake {
  x: number
  y: number
  vx: number
  vy: number
  radius: number
  alpha: number

  constructor() {
    this.x = 0
    this.y = 0
    this.vx = 0
    this.vy = 0
    this.radius = 0
    this.alpha = 0
    this.reset()
  }

  reset() {
    this.radius = this.randBetween(1, 5)
    this.alpha = this.randBetween(0.3, 0.9)
    this.x = this.randBetween(0, window.innerWidth)
    this.y = this.randBetween(-window.innerHeight, -this.radius)
    this.vx = this.randBetween(-CONSTANTS.VX_MAX, CONSTANTS.VX_MAX)
    this.vy = this.randBetween(0, 1)
  }

  update() {
    if (this.vy < CONSTANTS.VY_MAX)
      this.vy += CONSTANTS.VY_ACCELERATE

    if (this.vx < CONSTANTS.VX_MAX)
      this.vx += this.randBetween(0, 0.3)

    if (this.vx > -CONSTANTS.VX_MAX)
      this.vx -= this.randBetween(0, 0.3)

    this.x += this.vx
    this.y += this.vy
    if (this.y + this.radius > window.innerHeight)
      this.reset()
  }

  randBetween(min: number, max: number) {
    return min + Math.random() * (max - min)
  }
}

export class Snow {
  canvas: HTMLCanvasElement
  ctx: CanvasRenderingContext2D | null
  updateBound: () => void
  width: number
  height: number
  snowFlakesNum: number
  snowFlakes: SnowFlake[]

  constructor() {
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.snowFlakes = []
    this.snowFlakesNum = (window.innerHeight * window.innerWidth) / 4000
    this.canvas = document.createElement('canvas')
    this.ctx = this.canvas.getContext('2d')
    this.canvas.width = this.width
    this.canvas.height = this.height

    this.onResize()
    window.addEventListener('resize', () => {
      this.onResize()
    })

    this.updateBound = this.update.bind(this)
    requestAnimationFrame(this.updateBound)

    this.createSnowFlakes()
  }

  onResize() {
    this.width = window.innerWidth
    this.height = window.innerHeight
    this.canvas.width = this.width
    this.canvas.height = this.height
  }

  createSnowFlakes() {
    for (let i = 0; i < this.snowFlakesNum; i++)
      this.snowFlakes.push(new SnowFlake())
  }

  mount(el: HTMLElement) {
    if (!isElement(el))
      throw new EvalError(`Could not append canvas to element, not a valid element`)

    el.appendChild(this.canvas)
  }

  update() {
    this.ctx?.clearRect(0, 0, window.innerWidth, window.innerHeight)
    this.snowFlakes.forEach((snowFlake) => {
      if (!this.ctx)
        throw new EvalError('Missing ctx')

      snowFlake.update()

      // draw snow flake
      this.ctx.save()
      this.ctx.fillStyle = CONSTANTS.FLAKE_COLOR
      this.ctx.beginPath()
      this.ctx.arc(snowFlake.x, snowFlake.y, snowFlake.radius, 0, Math.PI * 2)
      this.ctx.closePath()
      this.ctx.globalAlpha = snowFlake.alpha
      this.ctx.fill()
      this.ctx.restore()
    })

    requestAnimationFrame(this.updateBound)
  }
}

<script lang="ts" setup>
const modalStore = useModalStore()
const { showFilterMenu } = storeToRefs(modalStore)
</script>

<template>
  <ModalWrapper
    :show="showFilterMenu"
    :slide-from="{ mobile: 'left', desktop: 'left' }"
    :align-to="{ mobile: 'end', desktop: 'start' }"
    header-size="with-filters"
  >
    <template #modal-content>
      <FilterMenu />
    </template>
  </ModalWrapper>
</template>

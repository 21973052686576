import type { WatchStopHandle } from 'vue'

type States = 'hidden' | 'visible' | 'closed'

const WAITING_TIME = 30000

export function useSegmentedPopup() {
  const { activeSegmentId, isSubscribed } = useSegment()
  const storyblokStore = useStoryblokStore()
  const segmentedPopups = computed(() => storyblokStore.state.segmentedPopups)
  const segmentedPopup = computed(() => {
    return segmentedPopups.value?.find(popup => popup.segmentId === activeSegmentId.value)
  })

  const segmentedPopupState = ref<States>('hidden')
  let unWatch: WatchStopHandle | undefined

  if (segmentedPopup.value && !isSubscribed.value) {
    const hasLeftThePage = usePageLeave()
    const { idle } = useIdle(WAITING_TIME)
    const { scrolledToBottom, y } = scrollToBottom()

    unWatch = watch(
      () => [hasLeftThePage.value, segmentedPopupState.value, idle.value, scrolledToBottom.value, y.value],
      () => {
        if ((hasLeftThePage.value && segmentedPopupState.value !== 'closed') || (idle.value && segmentedPopupState.value !== 'closed' || scrolledToBottom.value <= y.value && segmentedPopupState.value !== 'closed')) {
          openSegmentedPopup()
        }
      },
    )
  }

  watch(isSubscribed, () => {
    if (isSubscribed.value) {
      closeSegmentedPopup()
    }
  })

  function closeSegmentedPopup() {
    segmentedPopupState.value = 'closed'

    if (unWatch) {
      unWatch()
    }
  }

  function openSegmentedPopup() {
    segmentedPopupState.value = 'visible'
    const identifier = segmentedPopup.value?.popupIdentifier

    if (identifier) {
      useGTM().pushPopup({
        popupIdentifier: identifier,
      })
    }

    if (unWatch) {
      unWatch()
    }
  }

  function scrollToBottom() {
    const body = document?.querySelector('body')
    const bodyHeight = body?.offsetHeight ?? 0
    const windowHeight = window?.innerHeight ?? 0
    const { y } = useWindowScroll()
    const scrolledToBottom = computed(() => {
      return ((bodyHeight - windowHeight) * 75) / 100
    })
    return {
      scrolledToBottom,
      y,

    }
  }

  return {
    segmentedPopupState,
    closeSegmentedPopup,
    scrollToBottom,
    segmentedPopup,
  }
}

<script setup lang="ts">
const productStore = useProductStore()
const modalStore = useModalStore()

const relatedProductsByColor = computed(() => productStore.relatedProductsByColor)

function hide() {
  modalStore.showColorSelectorList = false
}
</script>

<template>
  <aside class="color-list">
    <div class="color-list__header">
      <p>{{ $t('colorSelectorTitle') }}</p>
      <ButtonBasic
        :aria-label="$t('closeColorSelector')"
        class="close"
        size="small"
        design="no-border"
        icon-left="close"
        background-color="transparent"
        data-test="color-selector-close"
        @click="hide"
      />
    </div>
    <ProductColorSelectorCards
      class="products-grid"
      :products="relatedProductsByColor"
      :hide-card-info="false"
    />
  </aside>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/rules/edges';

.color-list {
  position: relative;
  width: 100%;
  height: 90vh;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 2.4rem 1.6rem;
  margin-top: 8rem;

  &:before {
    content: '';
    position: absolute;
    background: inherit;
    top: -2rem;
    right: 0;
    width: 100%;
    height: 2rem;
    @include scallop;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 2.4rem;
  }
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 3.6rem 1rem;
  overflow-y: scroll;
}

button.close {
  top: 0;
  right: 0;
  position: absolute;
  padding: 1.6rem 1.6rem 3rem 3rem;
  cursor: pointer;
}

@media (min-width: $tablet) {
  .color-list {
    height: 100vh;
    max-width: 54rem;
    margin: 0;

    &:before {
      width: 2rem;
      height: 100%;
      top: 0;
      left: -12px;
      --mask: radial-gradient(11.89px at calc(100% - 16.8px) 50%, #000 99%, #0000 101%) 0
          calc(50% - 16px) / 100% 32px,
        radial-gradient(11.89px at calc(100% + 8.8px) 50%, #0000 99%, #000 101%) calc(100% - 8px)
          50%/100% 32px repeat-y;
      -webkit-mask: var(--mask);
      mask: var(--mask);
      transform: rotate(180deg);
    }

    &__header {
      justify-content: space-between;
    }
  }
}
</style>

<template>
  <div class="check-group" data-test="collections-group">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/scss/rules/breakpoints';
@import '~/assets/scss/typography/body';

.check-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  padding-bottom: var(--spacing-m);
}
</style>

<script setup lang="ts">
import { Snow } from 'lib/page-overlay/snow'

const el = ref()

onMounted(() => {
  const snow = new Snow()
  snow.mount(el.value)
})
</script>

<template>
  <div id="page-overlay" ref="el" aria-hidden="true" />
</template>

<style>
#page-overlay {
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
}
</style>

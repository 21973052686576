<script lang="ts" setup>
import { defineProps } from 'vue';

interface Option {
  id: string
  value: boolean
  disabled: boolean
}

const props = defineProps<{
  option: Option
  filterKey: string
  fixedWidth?: boolean
}>()
defineEmits<{ (e: 'apply', value: boolean): void }>()

const isActiveByKeyboard = ref(false)

function getColor(value: string) {
  return value.split('-').pop() || 'black'
}
</script>

<template>
  <label
    :for="`option-${option.id.replace('!!', '')}`"
    :class="{ active: option.value, disabled: option.disabled, fixedWidth, 'active--keyboard': isActiveByKeyboard, 'colors': filterKey === 'color' }" :data-gtm-label-filter="option.id"
    :data-test="`${option.id}-button`"
  >
    <FilterImage :length-type="option.id" />
    <input
      :id="`option-${option.id.replace('!!', '')}`" type="checkbox" :value="option.id"
      :aria-label="`${$t(option.id)}`" :aria-checked="option.value" :aria-disabled="option.disabled" :checked="option.value"
      @change="$emit('apply', !option.value)" @focus="isActiveByKeyboard = true" @blur="isActiveByKeyboard = false"
    >
    <FilterColorDot
        v-if="filterKey === 'color'"
        :checked="option.value"
        :color="getColor(option.id)"
        aria-hidden="true"
      />
    <span aria-hidden="true">
      <slot name="name">
        {{ $t(option.id) }}
      </slot>
    </span>
  </label>
</template>

<style lang="scss" scoped>
@import '~/assets/scss/rules/breakpoints';
@import '~/assets/scss/typography/body';

label {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
  border: 1px solid var(--gray-border-darker);
  padding: 0.8rem 1.6rem;
  border-radius: 0.2rem;
  cursor: pointer;
  gap: 1.2rem;

  &.colors {
    flex-direction: row;
    gap: 0.8rem;
  }

  &.active,
  &:hover {
    border-color: var(--black);
  }

  &.active {
    background-color: var(--black);

    span {
      color: var(--white);
    }

    img {
      filter: invert(1);
    }
    &--keyboard {
      outline: 2px solid var(--black);
      outline-offset: -2px;    
    }
    &.active--keyboard {
      outline: 2px solid var(--red);  
    }
  }

  &.disabled {
    pointer-events: none;
    border-color: var(--gray-dark);
    &.active--keyboard {
      outline: 2px solid var(--gray-border-darker);
      outline-offset: -2px;   
    }

    span {
      color: var(--gray-dark);
    }
  }

  span {
    @include body2;

    &.bold {
      font-weight: bold;
    }
  }

  input {
    pointer-events: none;
    position: absolute;
    opacity: 0;
  }
}

.fixedWidth {
  width: clamp(100px, 30%, 30%);
}
</style>

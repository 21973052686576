<script lang="ts" setup>
import { capitalize } from 'lib/strings'

const props = withDefaults(
  defineProps<{
    expanded?: boolean
    active?: boolean
    title?: string
    focus?: boolean
  }>(),
  {
    expanded: false,
    active: false,
    title: '',
    focus: false,
  },
)

const emit = defineEmits<{
  (e: 'toggle'): void
  (e: 'clear'): void
}>()

const dropdownContent = ref<HTMLDivElement>()
const contentSample = ref<HTMLDivElement>()
const headerArea = ref<HTMLButtonElement>()

let timeout: ReturnType<typeof setTimeout> | undefined

function toggle() {
  if (timeout)
    return
  const actionClass = props.expanded ? 'is-hiding' : 'is-expanding'
  dropdownContent.value?.classList.add(actionClass)
  timeout = setTimeout(() => {
    dropdownContent.value?.classList.remove(actionClass)
    timeout = undefined
    emit('toggle')
  }, 250)
}

/**
 * Animation requires a max-height to be set on the content.
 */
function updateContentHeight() {
  if (!dropdownContent.value || !contentSample.value)
    return
  const { value: dropdown } = dropdownContent
  const { value: dropdownSample } = contentSample
  const height = dropdownSample.offsetHeight
  if (!height)
    return
  dropdown.style.setProperty('--dropdown-height', `${height}px`)
}

function onScreenResize() {
  window.addEventListener('resize', () => {
    updateContentHeight()
  })
}

onMounted(() => {
  if (props.focus)
    headerArea.value?.focus()
  updateContentHeight()
  onScreenResize()
})

onUpdated(() => {
  updateContentHeight()
})
</script>

<template>
  <div class="basic-dropdown" :class="{ expanded }" data-test="dropdown">
    <div ref="headerArea" class="header" @click.prevent="toggle">
      <h3 class="title">
        {{ capitalize(title) }}
      </h3>
      <button
        v-show="active"
        class="button clear"
        @click.stop="emit('clear')"
      >
        <img src="/icons/close.svg" :alt="$t('closeIcon')" width="13.5" height="13.5">
      </button>
      <button
        class="button toggle"
        @click.prevent="toggle"
      >
        <img src="/icons/chevron.svg" :alt="$t('dropdownIcon')" width="16" height="9.3">
      </button>
    </div>

    <div ref="contentSample" class="hidden">
      <slot name="dropdown-content" />
    </div>

    <div ref="dropdownContent" class="dropdown-content">
      <slot name="dropdown-content" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '~/assets/scss/rules/breakpoints';
@import '~/assets/scss/typography/body';

.hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  padding-right: 2rem;
}

.basic-dropdown {
  width: 100%;
  display: block;
}

.header {
  gap: 0.5rem;
  width: 100%;
  height: 4rem;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template: 'title clear toggle' / (1fr 3.5rem 3.5rem);
  outline: none;

  @media (min-width: $tablet) {
    height: 4.5rem;
  }

  .title {
    @include body2;
    grid-area: title;
  }

  .button {
    width: 100%;
    display: flex;
    height: 3.5rem;
    align-items: center;
    justify-content: flex-end;

    img {
      display: block;
    }
  }

  .button.clear {
    grid-area: clear;
  }

  .button.toggle {
    grid-area: toggle;
  }
}

.dropdown-content {
  max-height: 0;
  overflow: hidden;
  visibility: hidden;
}

.expanded .dropdown-content {
  visibility: visible;
  max-height: 100%;
}

.dropdown-content.is-expanding {
  visibility: visible;
  animation: show 0.25s linear forwards;
}

.dropdown-content.is-hiding {
  animation: hide 0.25s linear forwards;
}

.expanded:not(.is-expanding) .toggle {
  transform: scaleY(-1);
}

@media (min-width: $tablet) {
  .hidden {
    padding-right: 3rem;
  }
}

@keyframes show {
  0% {
    max-height: 0;
  }

  100% {
    max-height: var(--dropdown-height, 50rem);
  }
}

@keyframes hide {
  0% {
    max-height: var(--dropdown-height, 50rem);
  }

  100% {
    max-height: 0;
  }
}
</style>

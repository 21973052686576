<script setup lang="ts">
const modalStore = useModalStore()
const { showColorSelectorList } = storeToRefs(modalStore)
const el = ref(null)

function closeModal(e: MouseEvent) {
  if (
    e.target instanceof HTMLElement
    && (e.target.className.includes('slot-wrapper') || e.target.className.includes('container'))
  )
    modalStore.showColorSelectorList = false
}

onClickOutside(el, closeModal)

function select() {
  modalStore.showColorSelectorList = false
}
</script>

<template>
  <ModalWrapper
    :show="showColorSelectorList"
    :slide-from="{ mobile: 'left', desktop: 'right' }"
    :align-to="{ mobile: 'start', desktop: 'start' }"
    header-size="no-header"
  >
    <template #modal-content>
      <ProductColorList ref="el" @click="select" />
    </template>
  </ModalWrapper>
</template>

<style lang="scss" scoped>

</style>

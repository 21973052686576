export function getOrganisationSchema() {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'address': {
      '@type': 'PostalAddress',
      'addressLocality': 'Stockholm, Sweden',
      'postalCode': 'SE-115 26',
      'streetAddress': 'Karlavägen 102 4tr',
    },
    'email': 'contact@happysocks.com',
    'name': 'Happy Socks AB',
    'telephone': '+46 (0)8-6118702',
    'url': 'https://www.happysocks.com',
    'logo': 'https://media.happysocks.com/maintenance/happysocks.png',
  }
}
